<template>
  <div class="recent-purchases-cont block" ref="root">
    <div class="loading-wrapper" v-if="loading">
      <LoadingWidget type="circle" ref="loading"></LoadingWidget>
    </div>

    <div v-else ref="dataCont">
      <div class="flex monthly-data">
        <span class="month-name">
          <router-link :to="{ name: 'monthly', params: { year, month }}" class="link">
            {{monthName}}
          </router-link>
        </span>

        <span class="month-total">${{total}}</span>
      </div>

      <div class="flex flex-column recent-purchases">
        <div v-for="p in purchases" :key="p.id" class="purchase flex">
          <span class="date">{{p.date}} - </span>
          <span class="business-name">{{p.business.name}}</span>
          <span class="amount">${{p.amount}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingWidget from './../../../../common/elements/LoadingWidget'
import { getUrl } from '../../utils/fetch_helpers'
const STATS_LATEST_URL = `${process.env.VUE_APP_PIPES}/finances/stats/latest`

export default {
  name: 'RecentPurchasesVue',

  setup() {
  },

  data() {
    return {
      loading: true,
      stats: {},
      month: 0,
      year: 0,
      total: 0,
      date: this.$moment(),
    }
  },

  computed: {
    purchases() {
      return this.stats.purchases.map(p => {
        const d = this.$moment(p.date, 'YYYY-MM-DD')
        return {
          ...p,
          date: d.format('ddd [the] Do')
        }
      })
    },

    monthName() {
      return this.date.format('MMMM')
    }
  },

  methods: {
  },

  created() {
    getUrl(STATS_LATEST_URL).then(
      res => {
        this.stats = res
        this.total = res.total
        this.month = res.month
        this.year = res.year
        //this.purchases = res.purchases
        this.loading = false
      },
      err => {
        this.$refs.dataCont.innerText = 'error loading data'
        console.log(err)
      }
    )
  },

  components: {
    LoadingWidget
  },
}
</script>

<style lang="scss">
.recent-purchases-cont {

  .monthly-data {
    margin-bottom: $margin;

    .month-name,
    .month-total {
      font-size: $font-large;
      font-weight: $bold;
    }
    .month-total {
      margin-left: auto;
    }
  }

  .recent-purchases {
    .business-name {
      margin-left: $margin-small;
    }

    .amount {
      margin-left: auto;
    }
  }
}
</style>
