/*
 * v-click-outside="onclose"
 *
 */
exports.clickOutsideDirective = {
  beforeMount(el, binding) {
    el.clickOutsideHandler = (e) => {
      e.stopPropagation()

      if (el !== e.target || !el.contains(e.target)) {
        binding.value(e)
      }
    }

    document.addEventListener('click', el.clickOutsideHandler)
  },

  unmounted(el) {
    document.removeEventListener('click', el.clickOutsideHandler)
  }
}
