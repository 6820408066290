<template>
  <div class="user-profile-cont">
  </div>
</template>

<script>
export default {
  name: 'UserProfile',

  props: {
    userId: [Number, String]
  },

  data() {
    return {
    }
  },

  methods: {
  },

  created() {
  }
}

</script>

<style lang="sass" scoped>
</style>
