<template>
  <div class="category-item-cont flex" ref="root">
    <LoadingWidget v-show="loading" type="bar" ref="loading"></LoadingWidget>

    <div v-show="!loading">
      <div class="back-btn-cont">
        <ButtonVue
          label="Back"
          @btn-click="$router.go(-1)">
        </ButtonVue>
      </div>

      <div class="category-details" ref="detailsCont">
        <div class="category">
          <div class="name primary-text large-text">{{ category?.name }}</div>
        </div>

        <div class="recent-purchases flex flex-column">
          <div 
            v-for="purchase in category.recent"
            :key="purchase.id"
            class="recent-purchase flex">
            <span class="date">{{ purchase.date }}</span>
            <span class="business">{{ purchase.business?.name }}</span>
            <span class="amount">${{ purchase.amount }}</span>
          </div>
        </div>

        <div class="history-chart-wrapper">
          <div class="chart-cont" ref="chartCont"></div>
        </div>
      </div>
      
      <div class="error-cont hide" ref="errorCont">
      </div>
    </div>
  </div>
</template>

<script>
import ButtonVue from './../../../common/elements/ButtonVue'
import LoadingWidget from './../../../common/elements/LoadingWidget'

import { groupedBarChart } from './../charts/BarChart'
import { axGet } from './../utils/fetch_helpers'

const CATEGORY_URL = `${process.env.VUE_APP_PIPES}/finances/categories/`

export default {
  name: 'CategoryItem',

  props: [],

  data () {
    return {
      id: this.$route.params.id,
      category: {
        name: '',
        recent: [{}], //TODO format date
        history: [],
      },
      history: [],
      loading: true 
    }
  },

  methods: {
    showDetails(r) {
      this.category = r;
      this.loading = false
      this.history = this.$_.map(this.category.history, year => this.$_.map(year, month => month)).flat()
      this.createChart()
    },

    createChart() {
      const data = this.history
      console.log(data)

      const chart = groupedBarChart({
        data,
        cont: '.chart-cont',
        groupByKey: 'month',
        groupedKey: 'year',
        valueKey: 'total',
        width: this.$refs.root.clientWidth,
        height: 500,
      })

      this.$refs.chartCont.append(chart)
    },

    showError(err) {
      this.$refs.errorCont.innerText = err
      this.loading = false
    },
  },

  created () {
    axGet(`${CATEGORY_URL}${this.id}`).then(
      res => {
        this.showDetails(res)
      },
      err => {
        this.showError(err)
      }
    )
  },

  components: {
    ButtonVue,
    LoadingWidget,
  },
}
</script>

<style lang="scss" scoped>
.category-item-cont {
  margin: $margin 0;

  .category-details {
    margin: $margin 0;

    .recent-purchases {
      margin: $margin 0;
      width: 50%;

      .date {
        margin-right: $margin;
      }

      .amount {
        margin-left: auto;
      }
    }
  }
}
</style>
