import { defineStore } from 'pinia'
import { axGet } from '../utils/fetch_helpers'
//import { useCoreUserStore } from './CoreStore'

const URL = `${process.env.VUE_APP_PIPES}/finances/purchases/date`

export const useMonthlyStore = defineStore('monthlyPurchaseSet', {
  state: () => ({
    set: [],
    count: 0,
  }),

  getters: {
    getSet: (state) => state.set,
    getNextLink: (state) => state.next,
  },

  actions: {
    fetch(year, month) {
      return axGet(
        `${URL}/${year}/${month}`, 
        {
          headers: {'content-type': 'application/json'},
          withCredentials: true
        }
      ).then((res) => {
        this.set = res
        return res
      })
    },
  },
})
