<template>
  <div class="file-upload-cont">
    <div class="upload-btn-cont">
      <ButtonVue
        label="Import Purchases"
        @btn-click="onClickImport"
      ></ButtonVue>

      <input id="file-upload-input" type="file" accept=".csv" ref="fileUploadInput" @change="onUploadFile"/>
    </div>  

    <div class="import-modal-cont">
      <ModalVue ref="modal" headerText="Import Purchase CSV">
        <template #modal-body>
          <div class="upload-file-body">
            <div class="uploaded-filename">File: {{filename}}</div>           
            <div class="uploaded-file-sample-rows">
              <table>
                <tr v-for="row, i in sampleRows" :key="i">
                  <td v-for="data, j in row" :key="j">
                    <span>{{data}}</span>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </template>
        <template #modal-footer>
          <div ref="errorCont" class="error-cont hide"></div>
          <ButtonVue
            label="Cancel"
            type="outline"
            @btn-click="onClickCancel"
          ></ButtonVue>
          <ButtonVue
            ref="submitBtn"
            @btn-click="onClickSubmit"
          ></ButtonVue>
        </template>
      </ModalVue>
    </div>

    <div class="fullscreen-loading-bar" ref="fullScreenLoadingBar">
      <LoadingWidget type="bar" ref="loading"></LoadingWidget>
    </div>
  </div>
</template>

<script>
import ButtonVue from './../../../../common/elements/ButtonVue'
import LoadingWidget from './../../../../common/elements/LoadingWidget'
import ModalVue from './../../../../common/elements/ModalVue'
import { postUrl } from './../../utils/fetch_helpers'
import { usePurchaseStore } from './../../stores/PurchaseStore';
import { useCategoryStore } from './../../stores/CategoryStore';
import { useBusinessStore } from './../../stores/BusinessStore';

export default {
  name: 'PurchasesFileUploadVue',

  setup() {
    const purchaseSet = usePurchaseStore()
    const businessSet = useBusinessStore()
    const categorySet = useCategoryStore()

    return {
      businessSet,
      categorySet,
      purchaseSet,
    };
  },

  props:{
  },

  data() {
    return {
      filename: '',
      sampleRows: [],
    }
  },

  methods: {
    onClickImport() {
      this.$refs.fileUploadInput.click()
    },

    onClickSubmit() {
      const formData = new FormData();
      formData.append('file', this.file);
      postUrl(`${process.env.VUE_APP_PIPES}/finances/import/purchases`, formData, {}, {
        'Content-Disposition': `attachment; filename=${this.file.name}`,
      }).then(r => {
        if (r.status === 204) {
          this.$refs.modal.hide()
          this.resetStores()
        } else {
          this.showErrorMsg(r.message)
        }
      })
    },

    onClickCancel() {
      this.$refs.modal.hide()
    },

    onUploadFile(evt) {
      this.file = evt.target.files[0]
      this.filename = this.file.name
      this.$refs.modal.show()
      this.readFile()
    },

    readFile() {
      const reader = new FileReader()
      reader.onload = this.onloadFile
      reader.readAsText(this.file)
    },

    onloadFile(evt) {
      if (evt.target.readyState == 2) {
        const contents = evt.target.result
        const csv = this.$papa.parse(contents)
        this.sampleRows = csv.data.slice(0,3)
        this.sampleRows.push(['...'])
        this.sampleRows.push(...csv.data.slice(csv.data.length - 3, csv.data.length - 1))
      }
    },

    showFullscreenLoadingBar() {
      this.$refs.fullScreenLoadingBar.classList.add('loading')
    },

    hideFullscreenLoadingBar() {
      this.$refs.fullScreenLoadingBar.classList.remove('loading')
    },

    showErrorMsg(msg) {
      this.$refs.errorCont.innerText = msg
      this.$refs.errorCont.classList.remove('hide')
    },

    resetStores() {
      this.businessSet.reset()
      this.categorySet.reset()
      this.purchaseSet.reset()
    },
  },

  created() {
  },

  components: {
    ButtonVue,
    LoadingWidget,
    ModalVue,
  },
}
</script>

<style lang="scss">
.file-upload-cont {

  #file-upload-input {
    display: none;
  }

  .upload-file-body {
    .uploaded-filename {
      padding-bottom: $padding;
    }

    .uploaded-file-sample-rows {
      table {
        background-color: white;
        color: black;
        border-collapse: collapse;

        tr {
          td {
            padding: $padding-small;
            border: 1px solid black;
          }
        }
      }
    }
  }

  .fullscreen-loading-bar {
    display: none;

    &.loading {
      display: flex;
      height: 100vh;
      width: 100vw;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
