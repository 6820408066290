<template>
  <div class="modal-wrapper hide" ref="modal" @keyup.esc="hide()">
    <div class="modal-cont">
      <div class="modal-header">
        <div class="header-text">{{header}}</div>
        <div class="close-cont">
          <i class="material-icons close" @click="hide()">close</i>
        </div>
      </div>

      <div class="modal-body">
        <div class="flex">
          <div class="business-category">
            {{business.name}} - {{category.name}}
          </div>
          <div class="amount child-right">
            ${{blobData.amount}}
          </div>
        </div>
        
        <div class="body-text">
          {{blobData.fullText}}
        </div>
        
      </div>

      <!--div class="modal-footer">
        <div class="footer-text">{{footer}}</div>
        <div class="footer-btn-cont"></div>
      </div-->
    </div>
  </div>

</template>

<script>
export default {
  name: 'CalendarModal',

  props: {
    modalOptions: Object
  },

  data() {
    return {
    }
  },

  computed: {
    blobData() {
      return this.modalOptions.data || {}
    },

    header() {
      return this.modalOptions.header
    },

    business() {
      return this.blobData?.business || {}
    },

    category() {
      return this.blobData?.category || {}
    },

    footer() {
      return this.modalOptions.footer
    }
  },

  methods: {
    show() {
      this.$refs.modal.classList.remove('hide')
    },

    hide() {
      this.$refs.modal.classList.add('hide')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./../../../common/styles/modal";
.body-text {
  margin-top: $margin;
}
</style>
