<template>
  <div class="multi-button-cont" :id="id" ref="cont">
    <ButtonVue
      class="primary-btn"
      ref="primaryBtn"
      :label="label"
      @btn-click="onBtnClick"
      :size="size">
    </ButtonVue>

    <div class="buttons-cont hide" ref="buttons">
      <ButtonVue 
        v-for="btn in options"
        :label="btn.label"
        type="outline"
        :size="size"
        :data="btn.data"
        @btn-click="onMultiBtnClick($event, btn)">
      </ButtonVue>
    </div>
 </div>
</template>

<script>
import ButtonVue from './ButtonVue'

export default {
  name: "MultiButtonVue",

  props:{
    id: String,
    size: {
      type: String,
        default: 'square'
    },
    type: String,
    label: {
      type: String,
      default: '<i class="material-icons">add</i>'
    },
    options: Array,
  },

  data() {
    return {}
  },

  methods: {
    onBtnClick() {
      this.toggleBtn()
    },

    onMultiBtnClick(evt, btn) {
      this.toggleBtn()
      btn.click(evt.event, evt.name)
    },

    toggleBtn() {
      this.$refs.buttons.classList.toggle('hide')
      this.$refs.cont.classList.toggle('rotate45')
    },
  },

  created() {
  },

  components: {
    ButtonVue
  }
}
</script>

<style lang="scss">
.multi-button-cont {
  position: relative;

  .buttons-cont {
    position: absolute;
    top: 60px;
    padding: $padding;
    background-color: $dark-mode-surface;
    border: 1px solid $color-primary-dark;
    border-radius: $border-radius;

    .hide {
      display: none;
    }

    .btn-cont {
      margin: $margin 0;
    }
  }

  .primary-btn {
    .btn {
      i.material-icons {
        transition: transform 0.25s;
      }
    }
  }

  &.rotate45 {
    .primary-btn {
      .btn {
        i.material-icons {
          transform: rotate(45deg);
          display: inline-block;
        }
      }
    }
  }
}
</style>
