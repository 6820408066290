<template>
  <div class="loading-widget-cont">
    <div v-if="type == 'bar'" class="loading-bar-cont" :class="cssClasses">
      <span class="loading-bar"></span>
    </div>
    <div v-else-if="type == 'circle'" class="loading-circle-cont" :class="cssClasses" :style="size">
      <span class="loading-circle"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingWidget',
  props: {
    type: String,
    cssClasses: String,
    size: Object,
  },
  methods: {
    hide() {
      this.$el.firstChild.classList.add('hide')
    },
    show() {
      this.$el.firstChild.classList.remove('hide')
    }
  },
}
</script>

<style scoped lang="scss">
.loading-widget-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  
  .loading-bar-cont {
    margin: $margin;
    position: relative;
    background-color: $light-gray;
    height: 10px;
    width: 200px;
    overflow: hidden;
    
    .loading-bar {
      background-color: $color-sec;
      display: block;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      animation: bar2 2.5s infinite;
    }
  }

  .loading-circle-cont {
    position: relative;
    height: 35px;
    width: 35px;

    &.small {
      height: 25px;
      width: 25px;
    }
    
    .loading-circle {
      background-color: $gray;
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
      border-radius: 50%;
      animation: circle 3s infinite linear;
    }

    &.dark {
      background-color: $dark-gray;
    }
  }
}

@keyframes circle {
  0% {
    clip-path: polygon(50% 50%, 50% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%, 50% 0%);
  }
  10% {
    clip-path: polygon(50% 50%, 100% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%, 50% 0%);
  }
  20% {
    clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 0% 100%, 0% 0%, 50% 0%);
  }
  30% {
    clip-path: polygon(50% 50%, 0% 100%, 0% 100%, 0% 100%, 0% 100%, 0% 0%, 50% 0%);
  }
  40% {
    clip-path: polygon(50% 50%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 50% 0%);
  }
  50% {
    clip-path: polygon(50% 50%, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }
  60% {
    clip-path: polygon(50% 50%, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
  }
  70% {
    clip-path: polygon(50% 50%, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%, 100% 100%);
  }
  80% {
    clip-path: polygon(50% 50%, 50% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 100%, 0% 100%);
  }
  90% {
    clip-path: polygon(50% 50%, 50% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%, 0% 0%);
  }
  100% {
    clip-path: polygon(50% 50%, 50% 0%, 100% 0%, 100% 100%, 0% 100%, 0% 0%, 50% 0%);
  }
}

@keyframes bar {
  0% {
    width: 0;
    left: 0;
  }
  50% {
    width: 110%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes bar2 {
  0% {
    width: 20%;
    left: -20%;
  }
  50% {
    width: 40%;
  }
  100% {
    width: 20%;
    left: 100%;
  }
}
</style>
