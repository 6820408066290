<template>
  <div class="ytd-cont block">
    <div class="loading-wrapper" v-if="loading">
      <LoadingWidget type="circle" ref="loading"></LoadingWidget>
    </div>
    <div v-else>
      <div class="flex">
        <div class="year">{{year}}</div>
        <div class="total">${{total}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import LoadingWidget from './../../../../common/elements/LoadingWidget'
const STATS_YTD_URL = `${process.env.VUE_APP_PIPES}/finances/stats/year`

export default {
  name: 'YearToDateVue',

  data() {
    return {
      loading: true,
      total: 0,
      year: 0,
    }
  },

  created() {
    this.$http.get(
      STATS_YTD_URL,
      {
        headers: {'content-type': 'application/json'},
        withCredentials: true
      }
    ).then(r => {
      // TODO make a wrapper to avoid this r.data
      this.total = r.data.year_to_date
      this.year = r.data.year
      this.loading = false
    }).catch(() => {
      this.loading = false
    })
  },

  components: {
    LoadingWidget,
  },
}
</script>

<style lang="scss">
.ytd-cont {
  .year, .total {
    font-size: $font-large;
    font-weight: $bold;
  }
  .total {
    margin-left: auto;
  }
}
</style>
