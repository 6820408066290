<template>
  <div class="five-year-bar-chart-cont block" ref="root">
    <div class="loading-wrapper" v-show="loading">
      <LoadingWidget type="circle" ref="loading"></LoadingWidget>
    </div>

    <div v-show="!loading">
      <div class="chart-cont" ref="chartCont"></div>
    </div>
  </div>
</template>

<script>
import LoadingWidget from './../../../../common/elements/LoadingWidget'

import { groupedBarChart } from './../../charts/BarChart'
import { axGet } from '../../utils/fetch_helpers'
const FIVE_YEAR_TOTALS_URL= `${process.env.VUE_APP_PIPES}/finances/stats/five-years`

export default {
  name: 'FiveYearBarChart',

  setup() {
  },

  data() {
    return {
      loading: true,
      stats: {},
    }
  },

  computed: {
  },

  methods: {
    createChart() {
      let data = this.stats

      // mobile break 480 - margin (10) x2
      if (this.$refs.root.offsetWidth <= 460) {
        const now = this.$moment()
        const months = [
          now.format('MMM'),
          now.subtract(1, 'months').format('MMM'),
          now.subtract(1, 'months').format('MMM'),
          now.subtract(1, 'months').format('MMM'),
          now.subtract(1, 'months').format('MMM'),
          now.subtract(1, 'months').format('MMM'),
        ]
        data = data.filter(d => months.includes(d.month))
      }

      const chart = groupedBarChart({
        data,
        cont: '.chart-cont',
        groupByKey: 'month',
        groupedKey: 'year',
        valueKey: 'total',
        width: this.$refs.root.clientWidth,
        height: 500,
      })

      this.$refs.chartCont.append(chart)
    },

    onResize() {
      this.$refs.chartCont.innerHTML = null
      this.createChart()
    }
  },

  created() {
  },

  mounted() {
    axGet(FIVE_YEAR_TOTALS_URL).then(
      res => {
        this.loading = false
        this.stats = this.$_.map(res, year => this.$_.map(year, month => month)).flat()
        this.createChart()       

        window.addEventListener('resize', this.onResize)
      },
      () => {
        this.loading = false
        this.$refs.chartCont.innerText = 'error loading'
      }
    )
  },

  unmounted() {
    window.removeEventListener('resize', this.onResize)
  },

  components: {
    LoadingWidget,
  },
}
</script>

<style lang="scss">
.five-year-bar-chart-cont {
  grid-column: 1 / 3;
}
</style>
