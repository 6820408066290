import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './config'
import { clickOutsideDirective } from '../../common/directives/click_outside'

const app = createApp(App)
import router from './router'

const pinia = createPinia()

import lodash from 'lodash'
app.config.globalProperties.$_ = lodash

import Papa from 'papaparse'
app.config.globalProperties.$papa = Papa

import axios from 'axios'
import VueAxios from 'vue-axios'

import moment from 'moment'
app.config.globalProperties.$moment = moment

app.directive('click-outside', clickOutsideDirective)

app.use(router)
app.use(pinia)
app.use(VueAxios, axios)
app.mount('#app')
