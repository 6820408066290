<template>
  <div class="login-cont flex flex-column" @keyup.enter="submitLoginCreds">
    <div class="error-cont hide" ref="errorCont">
      <div class="error-msg">Invalid Credentials</div>
    </div>

    <InputVue
      ref="usernameInput"
      cssClasses="username-cont"
      inputName="username"
      inputLabel="Username"
      :focus="focusUsername"
      v-model="username"
    >
    </InputVue>

    <InputVue
      ref="passwordInput"
      cssClasses="password-cont"
      inputName="password"
      inputLabel="Password"
      inputType="password"
      v-model="password"
    >
    </InputVue>

    <div class="show-password-cont">
      <input ref="showPassword" id="show-password" type="checkbox" name="show-password" @click="toggleShow"/>
      <label for="show-password">Show password</label>
    </div>

    <div class="login-btn-cont">
      <ButtonVue
        ref="loginBtn"
        size="btn-full-width"
        @btn-click="submitLoginCreds"
      >
      </ButtonVue>
    </div>
  </div>
</template>

<script>
import ButtonVue from './../../../common/elements/ButtonVue';
import InputVue from './../../../common/elements/InputVue';
import { useCoreUserStore } from './../stores/CoreStore';

export default {
  name: 'LoginCard',

  setup() {
    const coreUser = useCoreUserStore();
    return {
      coreUser,
    };
  },

  data() {
    return {
      username: '',
      password: '',
      pwInput: true,
      focusUsername: true,
      showPassword: false,
    };
  },

  methods: {
    clearErrors() {
      this.$refs.usernameInput.hideError();
      this.$refs.passwordInput.hideError();
      this.$refs.errorCont.classList.add('hide');
    },

    submitLoginCreds() {
      this.clearErrors();
      if (!this.username) {
        this.$refs.usernameInput.showError();
      }
      if (!this.password) {
        this.$refs.passwordInput.showError();
      }

      this.$refs.loginBtn.showSpinner();
      const data = {
        username: this.username,
        password: this.password,
      }
      return this.coreUser.login(data).then((r) => {
        this.$emit('login-success');
        return r
      }).catch(err => {
        this.$refs.errorCont.classList.remove('hide')
        this.$refs.loginBtn.hideSpinner();
        return err
      });
    },

    toggleShow() {
      this.$refs.passwordInput.togglePasswordType(this.$refs.showPassword.checked)
    },
  },

  created() {},

  components: {
    ButtonVue,
    InputVue,
  },
};
</script>

<style scoped lang="scss">
.login-cont {
  width: 300px;
  padding: $padding-small;
  margin: 0 auto;

  > div {
    padding: $padding-small;
    width: 100%;

    .login-input {
      width: 100%;
    }
  }
}
</style>
