exports.createPurchaseBtn = (clickFn) => {
  return {
    label: 'Purchase',
    type: 'purchase',
    data: {
      name: 'purchase',
    },
    click: clickFn,
  };
};

exports.purchaseModalData = (categories, businesses) => {
  return {
    headerText: 'Add Purchase',
    inputs: [
      { name: 'date', label: 'Date', type: 'date' },
      { name: 'amount', label: 'Amount', type: 'number', step: '0.01' },
      {
        name: 'category',
        label: 'Category',
        type: 'text',
        options: categories,
      },
      {
        name: 'business',
        label: 'Business',
        type: 'text',
        options: businesses,
      },
      { name: 'desc', label: 'Description', type: 'textarea' },
    ],
  };
};
