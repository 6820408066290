import axios from 'axios'
import { get, post, put } from './../../../common/utils/fetch_helpers'
import { getLocalValue } from './storage'

export const getUrl = (url, opts = {}) => {
  const at = getLocalValue('at')
  return get(url, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Token ${at}`,
    },
    ...opts,
  })
}

export const postUrl = (url, data, opts = {}, headers = {}) => {
  const at = getLocalValue('at')
  return post(url, data, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Token ${at}`,
      ...headers,
    },
    body: data,
    ...opts,
  }).then((r) => {
    return r
  })
}

export const putUrl = (url, data, opts = {}, headers = {}) => {
  const at = getLocalValue('at')
  return put(url, data, {
    mode: 'cors',
    credentials: 'include',
    headers: {
      Authorization: `Token ${at}`,
      ...headers,
    },
    body: data,
    ...opts,
  })
}

export const axPost = (url, data, opts = {}) => {
  return axios.post(url, data, {...opts}).then(
    res => {
      return res.data
    },
    errFunc
  )
}

export const axGet = (url, opts = {}) => {
  return axios.get(url, {...opts}).then(
    res => {
      return res.data
    },
    errFunc
  )
}

const errFunc =  (err) => {
  return Promise.reject({
    status: {
      code: err.response.status, 
      text: err.response.statusText,
    },
    data: err.response.data,
  })
}
