exports.categoryModalData = {
  headerText: 'Add Category',
  inputs: [
    { name: 'name', label: 'Name', type: 'text' },
    { name: 'priority', label: 'Priority', type: 'number' },
    { name: 'color', label: 'Color', type: 'text' },
    { name: 'group', label: 'Group', type: 'select' },
  ],
};

exports.createCategoryBtn = (clickFn) => {
  return {
    label: 'Category',
    type: 'category',
    data: {
      name: 'category',
    },
    click: clickFn,
  };
};
