<template>
  <div class="purchase-card-cont flex flex-column">
    <div class="back-btn-cont">
      <ButtonVue
        label="Back"
        @btn-click="$router.go(-1)">
      </ButtonVue>
    </div>

    <LoadingWidget v-if="loading" type="bar" ref="loading"></LoadingWidget>

    <div v-else class="purchase">
      <div  class="purchase-line-data flex flex-center">
        <div class="primary-text purchase-date">{{formattedDate}}</div>
        <div class="primary-text purchase-amount child-right">${{model.amount}}</div>
      </div>

      <div class="child-left">
        <div class="flex purchase-business">
          <span>Business:</span>
          <router-link :to="{ name: 'business', params: {id:model.business.id} }">
            <span class="link">{{model.business.name}}</span>
          </router-link>
        </div>

        <div class="flex purchase-category">
          <span>Category:</span>
          <router-link :to="{ name: 'category', params: {id: model.category.id} }">
            <span class="link">{{model.category.name}}</span>
          </router-link>
        </div>
      </div>
      
      <div class="purchase-details" ref="details">
        <div class="purchase-desc">{{model.desc}}</div>

        <div class="purchase-controls">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonVue from './../../../common/elements/ButtonVue'
import LoadingWidget from './../../../common/elements/LoadingWidget'

import { getUrl } from './../utils/fetch_helpers'

const PURCHASE_URL = `${process.env.VUE_APP_PIPES}/finances/purchases/`

export default {
  name: 'PurchaseItem',

  props: ['id'],

  data () {
    return {
      modelId: this.$route.params.id,
      model: undefined,
      loading: true,
    }
  },

  computed: {
    formattedDate() {
      console.log(this.model.date)
      const d = this.$moment(this.model.date, 'YYYY-MM-DD')
      console.log(d)
      return d.format('dddd, MMMM Do YYYY')
    }
  },

  methods: {
    fetchModel() {
      return getUrl(`${PURCHASE_URL}${this.modelId}/`)
    }
  },

  created() {
    this.fetchModel().then(r => {
      this.model = r;
      this.loading = false;
    })
  },

  components: {
    LoadingWidget,
    ButtonVue,
  },
}
</script>

<style lang="scss">
.purchase-card-cont {
  width: 100%;
  margin: $margin 0;

  .purchase {
    width: 100%;
    margin: $margin 0;

    .purchase-line-data {

      .purchase-amount {
      }
    }


    .purchase-business,
    .purchase-category {
      font-weight: $bold;
      margin-top: $margin;

      .link {
        margin-left: 4px;
      }
    }

    .purchase-details {
      transition: all 0.5s;

      :first-child {
        padding-top: $padding;
      }
    }
  }
}
</style>
