<template>
  <div class="calendar-cont">
    <div class="loading-wrapper" v-if="loading">
      <LoadingWidget type="bar" ref="loading"></LoadingWidget>
    </div>

    <div v-else class="calendar-wrapper">
      <div class="calendar-header">
        <div v-for="(day, index) in dayLabels"
          :key="index"
          class="day-header">
          {{day}}
        </div>
      </div>

      <div class="calendar-body">
        <div class="day-block"
          v-for="(day, index) in days"
          :key="index"
          :class="day.cssClasses">
          <div class="day-date-label flex justify-between">
            <span class="day">{{day.date}}</span>
            <span class="day-name">{{day.name}}</span>
            <span v-if="day.total" class="day-total child-right">${{formatTotal(day.total)}}</span>
            <span v-else class="day-total child-right">$0</span>
          </div>

          <div class="day-block-data">
            <div 
              v-for="(blob, index) in day.data"
              :id="blob.id"
              :key="index"
              :class="blob.classes"
              :style="{backgroundColor: blob.color}"
              @click="clickDataBlob(blob, $event)"
              class="data-blob flex">
              <span>{{blob.text}}</span>
              <span class="child-right">${{blob.amount}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <CalendarModal :modalOptions="modalOptions" ref="modal"/>
  </div>
</template>

<script>
import calendar from './../../../common/utils/calendar_helpers'
import CalendarModal from './CalendarModal'
import LoadingWidget from './../../../common/elements/LoadingWidget'
import { useMonthlyStore } from './../stores/MonthlyStore'

export default {
  name: 'CalendarVue',

  setup() {
    const purchaseSet = useMonthlyStore()
    return {
      purchaseSet,
    };
  },

  props: {
    year: Number,
    month: Number,
    today: {
      type: Object,
      default: new Date() 
    }
  },

  data () {
    return {
      loading: true, 
      dayLabels: calendar.DAYS,
      days: [],
      modalOptions: {},
    }
  },

  computed: {
    jsMonth() {
      return this.month - 1
    },
  },

  methods: {
    groupMonthlyData() {
      this.days = calendar.getDaysArray(this.year, this.jsMonth, this.today.getDate())

      this.purchaseSet.getSet.map(v => {
        const d = this.$moment(v.date)
        v = {
          ...v,
          text: this.$_.truncate(v.desc, {length: 10}),
          fullText: v.desc,
          color: v.category.color,
          moment: d,
        }
        if (!this.days[d.date()].total) this.days[d.date()].total = 0
        if (this.days[d.date()].data) {
          this.days[d.date()].data.push(v)
          this.days[d.date()].total += v.amount
        } else {
          this.days[d.date()] = {
            data: [v],
            total: v.amount,
          }
        }
      })
    },

    clickDataBlob(blob) {
      console.log(blob)
      this.modalOptions = {
        header: blob.moment.format('MM/DD/YYYY'),
        data: blob,
      }
      this.$refs.modal.show()
    },

    fetchMonthlyData() {
      this.purchaseSet.fetch(this.year, this.month).then((r) => {
        this.loading = false
        this.groupMonthlyData()
        return r
      })
    },

    formatTotal(amt) {
      return amt.toFixed(2)
    },
  },

  mounted() {
  },

  created() {
    this.fetchMonthlyData()
  },

  components: {
    CalendarModal,
    LoadingWidget,
  }
}
</script>

<style lang="scss" scoped>
@import "./../../../common/styles/calendar";
</style>
