<template>
  <div class="category-list-wrapper">
    <div class="loading-wrapper" v-if="loading">
      <LoadingWidget type="bar" ref="loading"></LoadingWidget>
    </div>

    <div v-else class="list-wrapper right">
      <ListCard 
        v-for="category in categoryList" 
        :key="category.id"
        :model="category">
      </ListCard>
    </div>
  </div>
</template>

<script>
import ListCard from './ListCard'
import LoadingWidget from './../../../common/elements/LoadingWidget'
import { useCategoryStore } from './../stores/CategoryStore'

export default {
  name: 'CategoryList',

  setup() {
    const categorySet = useCategoryStore()
    return {
      categorySet
    }
  },

  props: {
  },

  data () {
    return {
      loading: true,
    }
  },

  computed: {
    categoryList() {
      return this.categorySet.getSet.map(c => {
        return {
          ...c,
          cardData: {
            center: c.name,
            details: {
              link: {
                name: 'category',
                params: { id: c.id },
              },
            },
          },
        }
      })
    }
  },

  methods: {
    onSave(data) {
      console.log('category save', data)
    }
  },

  created() {
    this.categorySet.fetch().then(() => {
      this.loading = false
    })
  },

  components: {
    ListCard,
    LoadingWidget,
  }
}
</script>

<style lang="scss" scoped>
.category-list-wrapper {
  height: 100%;
}
</style>
