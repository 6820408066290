<template>
  <div class="btn-cont" :class="size" :id="btnId">
    <button 
      class="btn" 
      :class="type"
      @click="clickBtn"
      @keyup.enter="keyupEnter">
      <span class="flex" ref="labelText" v-html="label"></span>
      <LoadingWidget
        class=""
        ref="spinner"
        type="circle"
        :size="circleSize"
        cssClasses="small hide">
      </LoadingWidget>
    </button>
  </div>
</template>

<script>
import LoadingWidget from './LoadingWidget'

export default {
  name: 'ButtonVue',

  props: {
    id: String,
    size: {
      type: String,
      default: 'btn-med'
    },
    type: {
      type: String,
      default: 'solid',
    },
    label: {
      type: String,
      default: 'Submit'
    },
    data: Object,
  },

  data () {
    return {
      btnId: this.id,
      circleSize: {height: '19px', width: '19px'},
    }
  },

  methods: {
    clickBtn(e) {
      let name = this.label
      if (this.data) name = this.data.name
      this.$emit('btn-click', {event: e, name: name})
    },

    keyupEnter(e) {
      this.$emit('key-enter', e)
    },

    showSpinner() {
      this.$refs.spinner.show()
      this.hideText()
    },

    hideSpinner() {
      this.$refs.spinner.hide()
      this.showText()
    },

    showText() {
      this.$refs.labelText.classList.remove('hide')
    },

    hideText() {
      this.$refs.labelText.classList.add('hide')
    },
  },

  created() {
    if (!this.id) {
      this.btnId = `btn-${this.$_.uniqueId()}`
    }
  },

  components: {
    LoadingWidget,
  },
}
</script>

<style scoped lang="scss">
.btn-cont {
  .btn {
    padding: $padding-small $padding;
    border: 3px solid $color-primary;
    border-radius: $border-radius;
    //background-color: $color-primary;
    //color: $white;
    font-weight: 500;
    cursor: pointer;

    &:active {
      background-color: $color-primary-75;
      color: $white;
    }

    &.solid {
      background-color: $color-primary;
      color: $white;
      
      &:hover {
        background-color: $white;
        color: $color-primary;
      }
    }

    &.outline {
      background-color: $white;
      color: $color-primary;

      &:hover {
        background-color: $color-primary;
        color: $white;
      }
    }
  
    &.danger {
      border-color: $red;
      background-color: $red;

      &:hover {
        background-color: $white;
        color: $red;
      }

      &:active {
        background-color: $red-75;
        color: $white;
      }
    }
  }

  &.btn-full-width {
    .btn {
      width: 100%;
      margin: 0;
    }
  }

  &.btn-small {
    .btn {
      padding: 2px $padding-small;
    }
  }

  &.btn-med {
    .btn {
      padding: $padding $padding-med;
    }
  }

  &.btn-large {
    .btn {
      padding: $padding-med $padding-large;
    }
  }

  &.square {
    .btn {
      padding: 8px; //$padding;
    }
  }
}
</style>
