const statusCatch = (res) => {
  if (res.status != 200) {
    return res
  }
  return res.json()
}

export const queryParams = (params) => {
  return Object.keys(params)
    .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
    .join('&')
}

export const get = (url, opts = {}) => {
  let fullUrl = `${url}`
  if (opts.queryParams) {
    fullUrl = `${url}?${queryParams(opts.queryParams)}`
    delete opts.queryParams
  }
  opts = {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
    //mode: 'cors',
    //credentials: 'include',
    ...opts
  }
  return fetch(fullUrl, opts).then(statusCatch).catch(res => {
    return res
  })
}

export const post = (url, body, opts = {}) => {
  let fullUrl = `${url}`
  if (opts.queryParams) {
    fullUrl = `${url}?${queryParams(opts.queryParams)}`
    delete opts.queryParams
  }
  if (typeof body === 'Object') body = JSON.stringify(body)
  opts = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    credentials: 'include',
    body,
    ...opts
  }
  return fetch(fullUrl, opts).then(statusCatch).catch(res => {
    return res
  })
}

export const put = (url, body, opts = {}) => {
  let fullUrl = `${url}`
  if (opts.queryParams) {
    fullUrl = `${url}?${queryParams(opts.queryParams)}`
    delete opts.queryParams
  }
  if (typeof body === 'Object') body = JSON.stringify(body)
  opts = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
    credentials: 'include',
    body,
    ...opts
  }
  return fetch(fullUrl, opts).then(statusCatch).catch(res => {
    return res
  })
}
