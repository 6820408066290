exports.businessModalData = {
  headerText: 'Add Business',
  inputs: [
    { name: 'name', label: 'Name', type: 'text' },
    {
      name: 'defaultCategory',
      label: 'Default Category',
      type: 'text',
    },
  ],
};

exports.createBusinessBtn = (clickFn) => {
  return {
    label: 'Business',
    type: 'business',
    data: {
      name: 'business',
    },
    click: clickFn,
  };
};
