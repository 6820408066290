<template>
  <div class="business-list-wrapper">
    <div class="loading-wrapper" v-if="loading">
      <LoadingWidget type="bar" ref="loading"></LoadingWidget>
    </div>

    <div v-else class="list-wrapper right" @scroll="scrollCheck">
      <ListCard 
        v-for="business in businessList" 
        :key="business.id"
        :model="business">
      </ListCard>
    </div>
  </div>
</template>

<script>
import ListCard from './ListCard'
import LoadingWidget from './../../../common/elements/LoadingWidget'
import { useBusinessStore } from './../stores/BusinessStore'

export default {
  name: 'BusinessList',
  
  setup() {
    const businessSet = useBusinessStore()

    return {
      businessSet
    }
  },

  data() {
    return {
      lineCenterKey: 'name',
      loading: true,
      newBusinessName: '',
      newBusinessDefCat: '',
      focus: true,
    }
  },

  computed: {
    businessList() {
      return this.businessSet.getSet.map(b => {
        return {
          ...b,
          cardData: {
            center: b.name,
            details: {
              link: {
                name: 'business',
                params: { id: b.id },
              },
            },
          },
        }
      })
    }
  },

  methods: {
    scrollCheck(e) {
      const t = e.target
      if (t.scrollTop >= (t.scrollHeight - t.offsetHeight) && !!this.purchaseSet.getNextLink) {
        this.showLoading()
        this.purchaseSet.fetch(this.purchaseSet.getNextLink, true)
          .then(() => {
            this.hideLoading()
          })
      }
    },
  },

  created() {
    this.businessSet.fetch().then(() => {
      this.loading = false
    })
  },

  components: {
    ListCard,
    LoadingWidget,
  }
}
</script>

<style lang="scss" scoped>
.business-list-wrapper {
  height: 100%;
}
</style>
