import axios from 'axios'
import { defineStore } from 'pinia'

import { axPost, axGet } from './../utils/fetch_helpers'
import * as storage from './../utils/storage.js'

const checkTokenUrl = `${process.env.VUE_APP_PIPES}/auth/check`
const loginUrl = `${process.env.VUE_APP_AUTH}/login`
const logoutUrl = `${process.env.VUE_APP_AUTH}/logout`

export const useCoreUserStore = defineStore('coreUser', {
  state: () => ({
    user: {},
    res: [],
  }),

  getters: {
    getUserId: (state) => state.userId,
  },

  actions: {
    login(data) {
      return axPost(loginUrl, data).then(
        res => {
          storage.setLocalValues({ at: res.token, user: res.user });
          axios.defaults.headers.common['Authorization'] = `Token ${storage.getLocalValue('at')}`
          this.userId = res.user.id;
          return res
        }
      )
    },

    checkToken() {
      return axGet(checkTokenUrl).then((res) => {
        this.res = res
        axios.defaults.headers.common['Authorization'] = `Token ${storage.getLocalValue('at')}`
        return res
      })
    },

    logout() {
      return axPost(logoutUrl, JSON.stringify({})).then(
        () => {
          storage.deleteLocalValue('at')
          storage.deleteLocalValue('user')
          axios.defaults.headers.common['Authorization'] = ''
        },
        err => {
          return Promise.reject(err)
        }
      )
    },
  },
})
