import * as d3 from 'd3'

export const groupedBarChart = ({
  data,
  cont,
  groupByKey, // key for distinct bar groups
  groupedKey, // key for set within a group
  valueKey, // key for the values of each bar
  width,
  height,
}) => {
  const marginLeft = 50
  const marginRight = 30
  const marginTop = 30
  const marginBottom = 30

  const svg = d3.create('svg')
      .attr('viewBox', [0, 0, width, height])
      .attr('width', width)
      .attr('height', height)
      .attr('style', 'max-width: 100%; height: auto;')

  const fx = d3.scaleBand()
      .domain(new Set(data.map(d => d[groupByKey])))
      .rangeRound([marginLeft, width - marginRight])
      .paddingInner(0.1)

  const groupSet = new Set(data.map(d => d[groupedKey]))

  const x = d3.scaleBand()
      .domain(groupSet)
      .rangeRound([0, fx.bandwidth()])
      .padding(0.05)

  const color = d3.scaleOrdinal()
      .domain(groupSet)
      .range(d3.schemeSpectral[groupSet.size])
      .unknown('#ccc')

  const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d[valueKey])]).nice()
      .range([height - marginBottom, marginTop])

  // append the bars
  svg.append('g')
      .selectAll()
      .data(d3.group(data, d => d[groupByKey]))
      .join('g')
        .attr('transform', ([g]) => `translate(${fx(g)},0)`)
      .selectAll()
      .data(([, d]) => d)
      .join('rect')
        .attr('x', d => x(d[groupedKey]))
        .attr('y', d => y(d[valueKey]))
        .attr('width', x.bandwidth())
        .attr('height', d => y(0) - y(d[valueKey]))
        .attr('fill', d => color(d[groupedKey]))

  // append the x-axis
  svg.append('g')
      .attr('transform', `translate(0, ${height - marginBottom})`)
      .call(d3.axisBottom(fx).tickSizeOuter(0))
      .call(g => g.selectAll('.domain').remove())

  // append the y-axis
  svg.append('g')
    .attr('transform', `translate(${marginLeft}, 0)`)
      .call(d3.axisLeft(y).ticks(null, 's'))
      .call(g => g.selectAll('.domain').remove())

  const tooltip = d3.select(cont)
    .append('div')
      .attr('class', 'tooltip')
      .style('position', 'absolute')
      .style('visibility', 'hidden')

  svg.selectAll('rect').on('mouseover', (d, i) => {
    const text = `${i.month} ${i.year} - $${i.total}`
    tooltip.style('visibility', 'visible')
      .style('top', `${d.y+5}px`)
      .style('left', `${d.x+5}px`)
      .text(text)

    d3.select(d.target)
      .attr('stroke', 'white')
      .attr('stroke-width', '2px')
  })

  svg.selectAll('rect').on('mouseout', d => {
    tooltip.style('visibility', 'hidden')

    d3.select(d.target)
      .attr('stroke', 'none')
  })

  const key = svg.append('g')
    .selectAll()
    .data(d3.group(data, d => d[groupedKey]))
    .join('g')
      .attr('transform', (d, i) => `translate(${75 + (50 * i)}, 20)`)

    key.append('rect')
      .attr('width', 40)
      .attr('height', 10)
      .attr('fill', d => color(d[0]))

    key.append('text')
      .attr('fill', 'white')
      .attr('y', 25)
      .text(d => d[0])

  return Object.assign(svg.node(), {scales: {color}})
}
