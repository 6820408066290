import { createWebHistory, createRouter } from 'vue-router'

import IndexOverview from './components/IndexOverview'
import ObjectList from './components/ObjectList'
import MonthVue from './components/Month'
import userRoutes from './components/user/routes'

const routes = [
  { 
    path: '/', 
    name: 'index',
    component: IndexOverview 
  },
  {
    path: '/login',
    name: 'login',
    component: IndexOverview
  },
  { 
    path: '/purchases', 
    name: 'purchases',
    component: ObjectList 
  },
  { 
    path: '/purchases/:id', 
    name: 'purchase',
    component: ObjectList,
    props: true
  },
  { 
    path: '/businesses', 
    name: 'businesses',
    component: ObjectList, 
    props: true,
  },
  { 
    path: '/businesses/:id', 
    name: 'business',
    component: ObjectList,
    props: true
  },
  { 
    path: '/categories', 
    name: 'categories',
    component: ObjectList,
    props: true,
  },
  { 
    path: '/categories/:id', 
    name: 'category',
    component: ObjectList,
    props: true
  },
  {
    path: '/purchases/:year/:month',
    name: 'monthly',
    component: MonthVue,
    props: (route) => ({year: parseInt(route.params.year), month: parseInt(route.params.month)}),
  },
  /*{
    path: '/.catchAll(.*)',
    name: 'NotFound',
    component: 'NotFoundComponent'
  },*/
  ...userRoutes
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
