import UserProfile from './Profile'

const userRoutes = [
  { 
    path: '/user/:id', 
    name: 'user',
    component: UserProfile,
    props: true
  }
]

export default userRoutes
