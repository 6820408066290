import { defineStore } from 'pinia'
import { getUrl, postUrl } from '../utils/fetch_helpers'
import { useCoreUserStore } from './CoreStore'

const URL = `${process.env.VUE_APP_PIPES}/finances/businesses/`

export const useBusinessStore = defineStore('businessSet', {
  state: () => ({
    set: [],
    count: 0,
    previous: undefined,
    next: undefined,
    fetched: false,
  }),

  getters: {
    getSet: (state) => state.set,
    toList: (state) => state.set.reduce((a, v) => (
      [...a, {id: v.id, name: v.name}]
    ), []),
  },

  actions: {
    fetch() {
      if (this.fetched) {
        return Promise.resolve()
      }

      return getUrl(`${URL}?all=1`).then(res => {
        this.set = res.results
        this.count = res.count
        this.previous = res.previous
        this.next = res.next
        this.fetched = true
      })
    },
  
    create(data) {
      return postUrl(
        URL,
        JSON.stringify({
          ...data,
          user_id: useCoreUserStore().userId
        }),
        {},
        {'Content-Type': 'application/json'}
      ).then(res => {
        this.set.push(res)
        return res
      })
    },

    reset() {
      this.fetched = false
    },
  },
})
