<template>
  <div id="app-cont" class="flex flex-center flex-column dark">
    <div class="nav-bar-cont flex flex-column flex-center">
      <div class="nav-title flex">
        {{ title }}
        <div class="nav-btn">
          <ButtonVue label="Logout" @btn-click="onClickLogout"></ButtonVue>
        </div>
      </div>

      <div v-if="authenticated" class="nav-links flex flex-center justify-even">
        <div
          class="nav-link flex flex-center"
          v-for="(link, i) in links"
          :key="i"
        >
          <router-link :to="{ name: link.href, params: link.params }" class="link">
            {{ link.name }}
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="authenticated" class="main-cont">
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <div v-else class="main-cont">
      <LoadingWidget
        type="bar"
        ref="loading"
        :class="{ hide: pageLoaded }"
      ></LoadingWidget>
      <LoginCard
        @login-success="setAuthentication"
        :class="{ hide: !pageLoaded }"
      ></LoginCard>
    </div>
  </div>
</template>

<script>
import LoginCard from './components/LoginCard'
import LoadingWidget from './../../common/elements/LoadingWidget'
import ButtonVue from './../../common/elements/ButtonVue'
import { getLocalValue } from './utils/storage'
import { useCoreUserStore } from './stores/CoreStore'

export default {
  name: 'App',

  setup() {
    const coreUser = useCoreUserStore()

    return {
      coreUser,
    }
  },

  data() {
    return {
      title: 'Personal Finance Tracker',
      authenticated: false,
      pageLoaded: false,
      today: new Date(),
    };
  },

  computed: {
    links() {
      const year = this.today.getFullYear()
      const month = this.today.getMonth() + 1
      return [
        { name: 'Home', href: 'index' },
        { name: 'Calendar', href: 'monthly' , params: { year, month }},
        { name: 'Purchases', href: 'purchases' },
        //{ name: 'Businesses', href: 'businesses' },
        //{ name: 'Categories', href: 'categories' },
      ]
    },
  },

  methods: {
    checkAuthentication() {
      if (getLocalValue('at')) {
        this.$http.defaults.headers.common['Authorization'] = `Token ${getLocalValue('at')}`

        this.coreUser.checkToken().then(
          res => {
            if (res.username !== undefined) {
              this.authenticated = true
            }
            this.pageLoaded = true
          },
          () => {
            this.pageLoaded = true
          }
        )
      } else {
        this.pageLoaded = true
      }
    },

    setAuthentication() {
      this.authenticated = true
    },

    onClickLogout() {
      this.coreUser.logout().then(() => {
        this.authenticated = false
      })
    },
  },

  created() {},

  mounted() {
    this.checkAuthentication()
  },

  components: {
    LoginCard,
    LoadingWidget,
    ButtonVue,
  },
};
</script>

<style lang="scss">
@import "./styles/app.scss";
.nav-title {
  .nav-btn {
    margin-left: auto;
  }
}
</style>
