<template>
  <div class="create-btn-and-modal-cont">
    <div class="create-btn-cont">
      <MultiButtonVue id="create-new-btn" :options="createBtns">
      </MultiButtonVue>
    </div>

    <div class="create-modal-cont">
      <ModalVue ref="modal" :headerText="headerText">
        <template #modal-body>
          <div class="add-entity-body">
            <InputVue
              v-for="input in inputs"
              :key="input.name"
              ref="inputs"
              :inputType="input.type"
              :inputName="input.name"
              :inputLabel="input.label"
              :inputStep="input.step"
              :focus="input.focus"
              :options="input.options"
            >
            </InputVue>
          </div>
        </template>

        <template #modal-footer>
          <div ref="errorCont" class="error-cont hide"></div>
          <ButtonVue
            label="Cancel"
            type="danger"
            @btn-click="onClickCancel"
          ></ButtonVue>
          <ButtonVue
            ref="saveBtn"
            label="Save"
            @btn-click="onClickSave"
          ></ButtonVue>
        </template>
      </ModalVue>
    </div>
  </div>
</template>

<script>
import MultiButtonVue from './../../../common/elements/MultiButtonVue';
import ModalVue from './../../../common/elements/ModalVue';
import InputVue from './../../../common/elements/InputVue';
import ButtonVue from './../../../common/elements/ButtonVue';
import { useBusinessStore } from './../stores/BusinessStore';
import { useCategoryStore } from './../stores/CategoryStore';
import { usePurchaseStore } from './../stores/PurchaseStore';
import { createPurchaseBtn, purchaseModalData } from './../libs/purchases';
import { createCategoryBtn, categoryModalData } from './../libs/categories';
import { createBusinessBtn, businessModalData } from './../libs/businesses';

export default {
  name: 'CreateBtnAndModal',

  setup() {
    const businessSet = useBusinessStore();
    const categorySet = useCategoryStore();
    const purchaseSet = usePurchaseStore();

    return {
      businessSet,
      categorySet,
      purchaseSet,
    };
  },

  props: {},

  data() {
    return {
      createBtns: [
        createPurchaseBtn(this.clickCreateBtn),
        createBusinessBtn(this.clickCreateBtn),
        createCategoryBtn(this.clickCreateBtn),
      ],

      activeObject: '',
    };
  },

  computed: {
    headerText() {
      if (!this.activeObject) return '';
      return this.modalData[this.activeObject].headerText;
    },

    inputs() {
      if (!this.activeObject) return '';
      return this.modalData[this.activeObject].inputs;
    },

    modalData() {
      return {
        purchase: purchaseModalData(
          this.categorySet.toList,
          this.businessSet.toList
        ),
        business: businessModalData,
        category: categoryModalData,
      };
    },
  },

  methods: {
    onClickCancel() {
      this.$refs.modal.hide();
    },

    onClickSave() {
      this.$emit('create_modal_save', this.activeObject, this.groupInputData());
      this.$refs.saveBtn.showSpinner();
      let def;
      switch (this.activeObject) {
        case 'business':
          def = this.createBusiness;
          break;
        case 'category':
          def = this.createCategory;
          break;
        case 'purchase':
          def = this.createPurchase;
          break;
      }

      def().then(
        () => {
          this.purchaseSet.reset()
          this.categorySet.reset()
          this.businessSet.reset()
          this.$refs.saveBtn.hideSpinner();
          this.resetInputs()
          this.$refs.modal.hide()
        },
        () => {
          this.$refs.saveBtn.hideSpinner();
          this.showError();
        }
      );
    },

    clickCreateBtn(v, name) {
      this.activeObject = name;
      this.$refs.modal.show();
    },

    showModal() {
      this.$refs.modal.show();
    },

    showError(msg) {
      this.$refs.errorCont.classList.remove('hide');
      this.$refs.errorCont.text(msg);
    },

    hideError() {
      this.$refs.errorCont.classList.add('hide');
    },

    groupInputData() {
      const data = this.$_.map(this.inputs, (v, i) => {
        let val = this.$refs.inputs[i].getValue();
        if (v.type === 'number') val = parseFloat(val);
        return { name: v.name, value: val };
      });
      const obj = {};
      this.$_.each(data, (v) => {
        obj[v.name] = v.value;
      });
      return obj;
    },

    createBusiness() {
      return this.businessSet.create(this.groupInputData());
    },

    createCategory() {
      return this.categorySet.create(this.groupInputData());
    },

    createPurchase() {
      return this.purchaseSet.create(this.groupInputData());
    },

    resetInputs() {
      this.$_.each(this.inputs, (v, i) => {
        this.$refs.inputs[i].reset()
      })
    },
  },

  created() {
    this.businessSet.fetch();
    this.categorySet.fetch();
  },

  components: {
    MultiButtonVue,
    ModalVue,
    InputVue,
    ButtonVue,
  },
};
</script>

<style lang="scss">
.create-btn-and-modal-cont {
  .modal-footer {
    .btn-cont {
      padding-right: $padding;
    }
  }
}
</style>
